.dashboard-nickname {
  float: right;
  white-space: nowrap;
  max-width: 40vw;
}

.dashboard-points {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.dashboard-points .value {
  font-weight: bold;
}

.token-list {
    display: flex;
    list-style: none;
    padding: 0px;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 0px;
    gap: 10px;
}

.token-list-title {
  padding-bottom: 3px;
  border-bottom: 1px solid var(--color-primary);
  margin-top: 15px;
}

.token-list .token-entry.token-not-minted {
  filter: grayscale();
}

.token-list .token-entry.token-not-minted::before {
  position: absolute;
  top: 5px;
  left: 10px;
  display: block;
  width: 100%;
  height: 100%;
  content: 'MINT';
  opacity: 0.7;
  letter-spacing: 2px;
  color: #fff;
  z-index: 2;
  font-weight: bold;
  pointer-events: none;
}

.token-list .token-entry img {
    width: 150px;
    height: 150px;
    border-radius: 15px;
    filter: brightness(0.5);
    cursor: pointer;
    border: 5px solid transparent;
}

.token-list .token-entry {
  position: relative;
  border-radius: 15px;
  --border: 1px solid var(--color-primary);
  width: 150px;
  height: 150px;
  --margin-right: 10px;
  --margin-bottom: 10px;
}

.token-list-md .token-entry img {
  width: 120px;
  height: 120px;
}

.token-list-md .token-entry {
  width: 120px;
  height: 120px;
}

.token-list-sm .token-entry img {
  width: 70px;
  height: 70px;
}

.token-list-sm .token-entry {
  width: 70px;
  height: 70px;
  border: 0px;
}

.token-list .token-entry .token-entry-locked.token-entry-captured {
  background-color: green;
}

.token-list .token-entry .token-entry-locked {
  position: absolute;
  border-radius: 15px;
  width: 150px;
  height: 150px;
  font-size: medium;
  opacity: 0.5;
  background-color: #ffffff;
  color:#000;
  z-index: 10;
  display: flex;
}

.token-list .token-entry .token-entry-locked * {
  width: 60%;
  height: 60%;
  margin: auto;
}

.token-list .token-entry .token-entry-requirements {
  position: absolute;
  top: -5px;
  left: -5px;
  font-weight: bold;
  color: #FFF;
  background-color: #000;
  border-radius: 10px;
  padding: 2px 10px;
  z-index: 1000;
  cursor: pointer;
}

.token-list .token-entry .token-entry-requirements img {
  width: 25px;
  height: 25px;
  border: 0;
  filter: none;
  margin-right: 5px;
}

.token-list .token-entry .token-entry-available {
  position: absolute;
  bottom: -5px;
  right: -5px;
  height: 30px;
  font-weight: bold;
  color: #FFF;
  background-color: #000;
  border-radius: 10px;
  padding: 2px 10px;
  z-index: 1000;
}

.token-list .token-entry.token-selected img, 
.token-list .token-entry img:hover {
    filter: none;
}

.token-list .token-entry .badge {
  position: absolute;
  --width: 30px;
  height: 30px;
  font-size: medium;
}

.token-list .token-entry .level {
  left: -8px;
  top: -5px;
  background-image: url("./../../css/images/icons/shield.png");
  background-size: cover;
  position: absolute;
  width: 40px;
  height: 40px;
  text-align: center;
  padding-top: 10px;
  font-size: small;
  cursor: pointer;
  font-weight: bold;
}

.token-list .token-entry .rarity {
  right: 6px;
  bottom: 5px;
  position: absolute;
  background-color: #ffffffaa;
  border-radius: 5px;
  padding: 2px;
  color: #000;
  font-weight: bold;
  font-size: small;
}

.token-list .token-entry .level:hover {
  zoom: 1;
}

.token-list .token-entry .levelup {
  right: 5px;
  bottom: 5px;
  background-image: url("./../../css/images/icons/level-up.png");
  background-size: cover;
  position: absolute;
  width: 40px;
  height: 40px;
  text-align: center;
  padding-top: 10px;
  font-size: small;
  cursor: pointer;
  font-weight: bold;
}

.token-list .token-entry .levelup:hover {
  zoom: 1.3;
}

.token-list .token-entry .badge.voucher {
  right: -5px;
  top: -5px;
}

.token-list .token-entry .badge.balance {
  right: -5px;
  bottom: -5px;
  z-index: 999;
}

.token-list-buttons {
  float: right;
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 3px;
}

.token-list-button {
  cursor: pointer;
}

.token-list-button + .token-list-button {
  margin-right: 10px;
}

.token-list-collapse {
  float: left;
  margin-right: 5px;
}

.rebel-rank {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.rebel-rank .rebel-rank-status {
  width: calc(100vw / 9);
  min-width: 70px;
  height: 40px;
  margin: 3px;
  padding: 3px 5px;
  border-radius: 5px;
}

.rebel-rank .rebel-rank-status.current-rank {
  background-color: #FFF;
  color: green;
  font-weight: bolder;
}

.rebel-rank .rebel-rank-status .fill {
  background-color: red;
  border-radius: 2px;
  height: 10px;
}

.rebel-rank .rebel-rank-status .fill.has-rank {
  background-color: green;
}

@media screen and (max-width: 600px) {
  .token-list .token-entry, .token-list {
    justify-content: center;
  }

  .token-list .token-entry, .token-list .token-entry img {
    width: 100px;
    height: 100px;
  }

  .token-list .token-entry img {
    filter: none;
  }

  .token-list .token-entry .token-entry-locked {
    width: 100px;
    height: 100px;
  }  

  .token-list-sm .token-entry, .token-list-sm .token-entry img {
    width: 50px;
    height: 50px;
  }

  .token-list .token-entry .token-entry-requirements img {
    width: 15px;
    height: 15px;
  }  

  .token-list .token-entry .token-entry-requirements span, .token-list .token-entry .token-entry-available {
    font-size: x-small;
  }

  .rebel-rank * {
    font-size: x-small;
  }

  .rebel-rank .rebel-rank-status {
    height: 30px;
    padding: 2px 3px;
  }

  .token-list .token-entry .level {
    width: 30px;
    height: 30px;
    padding-top: 8px;
    font-size: x-small;
  }

  .token-list .token-entry .levelup {
    width: 40px;
    height: 40px;
  }

  .token-list .token-entry .rarity {
    font-size: x-small;
  }  
}