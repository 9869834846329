.status {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-bottom: 10px;
  color: #fff;
  --background-color: #000;
  border-radius: 10px;
  padding: 12px;
}

.status .value {
  font-weight: bold;
}

.status .entry .icon {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}

.status .entry + .entry {
  margin-left: 10px;
}

@media screen and (max-width: 600px) {
  .status {
    font-size: x-small;
    padding: 7px 0px;
  }

  .status .entry .icon {
    width: 15px;
    height: 15px;
  }
}