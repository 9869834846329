.app-list {
  position: relative;
  width: 100%;
  margin: auto;
  margin-top: 10px;
  background-color: transparent;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  list-style: none;
  animation: 1s ease-out 0s 1 slideInFromTop;
  flex-wrap: wrap;
  gap: 10px;
}

.app-list-small {
  margin-top: 140px;
}

.app-list-medium {
  width: calc(100% - 20vw);
}

.app-entry {
  position: relative;
  text-align: center;
  cursor: pointer;
  padding: 5px;
  border-radius: 20px;
  height: 25vh !important;
  width: 30vw; 
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);
  user-select: none;
}

.app-list-small .app-entry {
  height: 150px !important;
  width: 150px;  
}

.app-entry h2 {
  font-size: small;
}

.app-entry img {
  width: 200px;
  height: 200px;
  object-fit: contain;
  opacity: 1;
}

.app-list-small .app-entry img {
  width: 80%;
  height: 80%;
}

.app-entry img:hover {
  object-fit: contain;
}

.app-entry-title {
  margin-top: 10px;
  font-size: x-large;
  font-weight: bold;
}

.app-list-small .app-entry-title {
  font-size: medium;
}

.app-entry-wide {
  height: 25vh !important;
  width: 60vw;  
  max-width: 600px;
}

.app-entry-full {
  height: 25vh !important;
  width: calc(60vw + 10px);
}

/* Apps */
.app-icon {
  filter: brightness(0.9);
}

.app-icon:hover {
  filter: brightness(1.1);
}

.app-resistance-terminal {
  --background-color: #8f69ff !important;
}

.app-resistance-terminal-missions {
  --background-color: #8f69ff !important;
}

.app-battle-for-eth {
  background-color: #aa63fa !important;
}

.app-mint-rebel {
  background-color: #ffe162 !important;
}

.app-the-resistance {
  background-color: #51944e !important;
}

.app-playground {
  background-color: #ff7171 !important;
}

.app-invasion-maze {
  background-color: #2babf1 !important;
}

.app-resistance-camp {
  background-color: #3f7c89 !important;  
}

.app-pixel-land {
  --background-color: #4b56d7 !important;
  background-image: url("./css/images/apps/rebel-city.png");
  background-position: center center;
  background-size: cover; 
}

.app-entry-disabled, .app-entry-disabled:hover {
  filter: brightness(1.2) grayscale();
  cursor: default;
}

.landing-main .connectbar {
  position: fixed;
  top: 10px;
  left: 10px;
  bottom: unset;
  width: calc(100% - 10px);
}

.landing-main .buttons-connect-mobile {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
}

.landing-main .buttons-connect-mobile .button-connect {
  top: unset;
  left: unset;
  position: relative;
  width: 150px;
}

.landing-main .button-connect {
  top: 10px;
  position: fixed;
  left: calc(50% - 75px);
  width: 150px;
  font-size: large;
}  

.app-extra-legend {
  position: absolute;
  left: 10px;
  top: 10px;
  padding: 5px 10px;
  background-color: var(--color-bg-primary-transparent);
  font-family: 'Baloo 2';
  border-radius: 12px;
  font-size: large;
}

@media screen and (max-width: 600px) {
  .app-list {
    margin-top: 0;
  }

  .app-list-small {
    margin-top: 90px;
  }
}

@media screen and (max-width: 800px) {
  .app-list-medium {
    width: 100%;
  }

  .app-entry {
    height: 23vh !important;
    width: 45vw !important;
  }

  .app-entry-full {
    width: calc(90vw + 10px) !important;
  }

  .app-list-small .app-entry {
    height: 100px !important;
    width: 100px !important;
  }

  .app-entry-title {
    margin-top: 0;
    font-size: large;
  }

  .app-list-small .app-entry-title {
    font-size: small;
  }

  .app-entry img {
    width: 100px;
    height: 80px;
  }

  .landing-main .buttons-connect-mobile .button-connect {
    top: unset;
    left: unset;
    position: relative;
    width: 100px;
    font-size: medium;
  }

  .app-extra-legend {
    zoom: 0.7;
  }
}