.menu-list {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: 80px;
  background-color: rgb(60, 60, 60);
  color: #522f8d;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  list-style: none;
  display: none;
  z-index: 1000;
  margin: 0;
}

.menu-list.is-nav-open {
  display: flex;
  animation: 0.5s ease-out 0s 1 slideInFromLeft;
}

.menu-entry {
  position: relative;
  text-align: center;
  cursor: pointer;
  padding: 5px;
}

.menu-entry + .menu-entry {
  margin-top: 25px;
}

.menu-entry h2 {
  font-size: small;
}

.menu-entry img {
  width: 45px;
  height: 45px;
  object-fit: contain;
  filter: invert();
  opacity: 0.4;
}

.menu-entry img:hover {
  opacity: 1;
}

.menu-entry .badge {
  position: absolute;
  width: 25px;
  height: 25px;
  font-size: small;
  right: -5px;
  bottom: -5px;
  padding: .35em;
}

@media screen and (max-width: 600px) {
  .menu-list {
    top: unset;
    height: 60px;
    width: 100vw;
    flex-direction: row;
    justify-content: space-evenly;
    position: fixed;
  }

  .menu-entry img {
    width: 30px;
    height: 30px;
  }

  .menu-entry + .menu-entry {
    margin-top: 0px;
  }

  .menu-list.is-nav-open {
    animation: 1s ease-out 0s 1 slideInFromBottom;
  }
}