/* Invader Game */
.invader-game-modal {
  max-width: 600px;
}

.invader-game-modal .modal-content{
  -width: 90vw;
  -height: 90vh;
}

@media screen and (max-width: 600px) {
  .invader-game-modal {
    width: 90vw;
    -height: 90vh;
    margin: auto;
    margin-top: 5vh;
  }
}