.resistance-hierarchy {
  position: relative;
  display: flex;
  list-style: none;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
}

.resistance-count {
  float: right;
}

.resistance-hierarchy > li {
  position: relative;
}

.resistance-hierarchy > li > img {
  width: 150px;
  height: 150px;
  border-radius: 15px;
}

.resistance-hierarchy > li > .level {
  left: -8px;
  top: -5px;
  background-image: url("./../../css/images/icons/shield.png");
  background-size: cover;
  position: absolute;
  width: 40px;
  height: 40px;
  text-align: center;
  padding-top: 10px;
  font-size: small;
  cursor: pointer;
  font-weight: bold;
}

.resistance-hierarchy > li > .rarity {
  right: 6px;
  bottom: 5px;
  position: absolute;
  background-color: #ffffffaa;
  border-radius: 5px;
  padding: 2px;
  color: #000;
  font-weight: bold;
  font-size: small;
}


.resistance-hierarchy > li > .level:hover {
  zoom: 1;
}

.resistance-hierarchy > li {
  margin: 5px;
}

@media screen and (max-width: 600px) {
  .resistance-hierarchy > li > img {
    width: 70px;
    height: 70px;
    border-radius: 7px;
  }

  .resistance-hierarchy > li > .level {
    width: 30px;
    height: 30px;
    padding-top: 6px;
    font-size: x-small;
  }  
  
  .resistance-hierarchy > li > .rarity {
    font-size: x-small;
    right: 2px;
    bottom: 2px;
  }  
}