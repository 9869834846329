.mission-list {
  list-style: none;
  padding-left: 10px;
}

.mission-list .mission-entry {
  font-size: large;
}

.mission-list .mission-entry.mission-completed {
  
}

.mission-list .mission-entry.mission-disabled {
  opacity: 0.4;
}

.mission-list .mission-entry.mission-pending, 
.mission-list .mission-entry.mission-alwayson {
  cursor: pointer;
}

.mission-list .mission-entry.mission-pending:hover,
.mission-list .mission-entry.mission-alwayson:hover {
  font-weight: bold;
}

.mission-list .mission-entry + .mission-entry {
  margin-top: 5px;
}

.mission-list .mission-entry .mission-entry-icon {
  margin-right: 10px;
}

.mission-list .mission-entry .mission-entry-icon.completed {
  color: greenyellow;
}

@media screen and (max-width: 600px) {
  .mission-list {
    padding-left: 0px;
  }

  .mission-list .mission-entry {
    font-size: medium;
  }
}
