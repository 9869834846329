.scenario {
    position: absolute;
    width: 100vw;
    height: 100vh;
}

.scenario .spot-container {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
}

.scenario .spot {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.scenario .spot .spot-legend {
    color: #ffffff;
    text-align: center;
    font-family: 'Baloo 2';
    font-size: xx-large;
    background-color: #ffffff55;
    width: 50px;
    padding: 4px;
    border-radius: 50%;
    margin: auto;
}

.scenario .spot .spot-image {
    text-align: center;
    margin: auto;
}

.scenario .spot .spot-image img {
    width: 100%;
}

.scenario-background-details {
    position: absolute;
}

.scenario-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.spot-container-background {
    position: absolute;
}

.scenario-legend {
    text-align: center;
    padding: 5px;
    font-size: 3em;
    font-family: 'Baloo 2';
    background-color: var(--color-bg-primary-transparent);
    color: #ffffff;
}

/* Building */
.scenario-building {
    background-image: url("./../css/images/scenarios/building/background.png");
    background-position: center center;
    background-size: cover;
}

.scenario-building .scenario-background-details-invader {
    position: absolute;
    top: 50px;
    animation: iterate-flip 20s linear infinite;
    -webkit-animation: iterate-flip 20s linear infinite;

    background-image: url("./../css/images/scenarios/building/background-details-invader.png");
    background-position: center center;
    background-size: cover;

    width: 150px;
    height: 150px;
}

.scenario-building .scenario-background-details-clouds {
    position: absolute;
    top: 50px;
    animation: lineal-pass 40s linear infinite;
    -webkit-animation: lineal-pass 40s linear infinite;

    background-image: url("./../css/images/scenarios/building/background-details-clouds.png");
    background-position: center center;
    background-size: cover;

    width: 300px;
    height: 160px;
}

.scenario-building .spot-container-background {
    background-image: url("./../css/images/scenarios/building/spot-container.png");
    background-repeat: no-repeat;
    background-size: 767px 1069px;

    width: 767px;
    height: 1069px;
    left: calc((100% - 766px) / 2);
    bottom: 0px;

    zoom: 0.7;
}

.scenario-building .spot-container {
    flex-direction: row;
    width: 80%;
    height: 95%;
    bottom: 0px;
    left: 50px;
    gap: 25px;
}

.scenario-building .spot {
    background-image: url("./../css/images/scenarios/building/spot.png");
    width: 105px;
    height: 129px;
}

.scenario-building .spot:hover,
.scenario-building .spot-selected {
    background-image: url("./../css/images/scenarios/building/spot-hover.png");
}

/* Dumpster */
.scenario-dumpster {

}

.scenario-dumpster .spot-container {
}

@media screen and (max-width: 900px) {
    .scenario-building .spot-container-background,
    .scenario-building .scenario-background-details-invader {
        zoom: 0.6;
    }
}

@media screen and (max-width: 600px) {
    .scenario-building .spot-container-background {
        zoom: 0.45;
    }
}

/* animations */
@keyframes iterate-flip {
    0% {
        -webkit-transform: rotateY(180deg); 
        left: -500px;
        top: 50px;
    }
    48% {
        -webkit-transform: rotateY(180deg); 
    }
    50% { 
        left: calc(100% + 500px);
        top: 200px;
        -webkit-transform: rotateY(0deg); 
    }
    99% {
        -webkit-transform: rotateY(0deg); 
    }
    100% {
        left: -500px;    
        top: 50px;
        -webkit-transform: rotateY(180deg);
    }
}

@keyframes lineal-pass {
    0% {
        left: -500px;
        top: 50px;
    }
    49% {
        top: 50px;
    }
    50% { 
        top: -1000px;
        left: calc(100% + 500px);
    }
    99% {
        top: -1000px;
    }
    100% {
        left: -500px;    
        top: 50px;
    }
}
