/* Invader Game */
.pick-game-modal {
  max-width: 600px;
}

.pick-game-modal .modal-content {
  -width: 90vw;
  -height: 90vh;
}

.pick-game-modal .modal-body {
  font-family: 'Baloo 2';
}

.pick-game-modal .button-help {
  width: 50px;
  margin-right: auto !important;
  font-size: xx-large;
}

.pick-game-modal .modal-icon {
  width: 150px;
  margin-bottom: 10px;
  padding: 5px;
  background-color: var(--color-bg-primary-transparent);
  border-radius: 50%;
}

.pick-game-result img {
  width: 30%;
  margin-bottom: 10px;
}

.pick-game-modal .counter {
  font-size: xx-large;
  border-radius: 10px;
  background-color: black;
  width: fit-content;
  margin: auto;
  padding: 5px 20px;
  font-weight: bold;
  margin-top: 10px;
  width: 200px;
}

.pick-game-modal .counter.counter-season {
  width: 250px;
}

.pick-game-modal .stats {
  display: flex;
  margin-bottom: 20px;
}

.pick-game-modal .stats > div {
  position: relative;
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pick-game-modal .stats > div div + div,
.pick-game-modal .stats > div div + img {
  margin-top: 10px;
}

.pick-game-modal .stats .stat-counter {
  padding: 15px 0px;
  width: 50px;
  border-radius: 50%;
  background-color: var(--color-bg-primary-transparent);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pick-game-modal .stats > div img {
  width: 70%;
}

.pick-game-modal .stats-legend {
  font-size: larger;
}

.pick-game-result {
  border-radius: 5px;
  background-color: var(--color-bg-secondary);
  margin-bottom: 15px;
  padding: 10px;
  font-size: larger;
}

@media screen and (max-width: 600px) {
  .pick-game-modal {
    width: 90vw;
    -height: 90vh;
    margin: auto;
    margin-top: 5vh;
  }

  .pick-game-modal .stats .stat-counter {
    zoom: 0.8;
  }  
}