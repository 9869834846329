.component-leaderboard a {
  text-decoration: none;
  color: #FFF;
  text-align: left;
}

.component-leaderboard a:hover {
  font-weight: bold;
}

.component-leaderboard .row-header {
}

.component-leaderboard .row-detail {
}

.component-leaderboard-logged-user {
  background-color: var(--color-bg-primary);
  color: var(--color-primary);
}

.user-count {
  float: right;
}

@media screen and (max-width: 600px) {
  .component-leaderboard {
    font-size: small;
  }
}