.landing-parallax {
    position: relative;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    perspective: 2px;
    
    background: rgba(75,122,171,1);
    background-image: linear-gradient(90deg, rgba(75,122,171,1) 0%, rgba(75,122,171,1) 0%, rgba(128,175,223,1) 100%);
    background-repeat: repeat-y;
    background-position: top left;
    background-size: cover;
}

.section-main {
    content: ' ';
    opacity: 0.6;
    position: absolute;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-image: url("./css/images/banner/banner-spaceship.png"), url("./css/images/banner/banner-title.png");
    background-position: center center;
    background-size: cover;
}  

.waitingbar {
    overflow: hidden;
    position: fixed;
    bottom: 110px;
    left: 15%;
    right: 15%;
    text-align: center;
    font-size: x-large;
}

.statusbar {
    overflow: hidden;
    position: fixed;
    --top: 10px;
    --left: 160px;
    --right: 160px;
    bottom: 80px;
    left: 15vw;
    right: 15vw;
    padding: 5px 10px;
    z-index: 2000;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
    font-size: x-large;
    background-color: var(--color-bg-primary);
    --background-color: var(--color-bg-primary-transparent);
    border-radius: 10px;
}

.versionbar {
    overflow: hidden;
    position: fixed;
    bottom: 10px;
    right: 0px;
    padding: 5px 10px;
    z-index: 2000;
    text-align: right;
    color: #ffffff;
    font-weight: bold;
    font-size: x-small;
}

.testmode-enabled {
    cursor: pointer;
    text-align: center;
    color: #ffffff;
    background-color: #2b445e;
    padding: 4px;
    margin-bottom: 2px;
    border-radius: 4px;
}

.connectbar {
    overflow: hidden;
    position: fixed;
    bottom: 80px;
    left: 0px;
    right: 0px;
    padding: 5px 10px;
    z-index: 2000;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.socialbar {
    overflow: hidden;
    position: fixed;
    bottom: 10px;
    left: 0px;
    right: 0px;
    padding: 5px 10px;
    z-index: 2000;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.socialbar .button {
    width: 50px;
    height: 50px;
}

.socialbar .button + .button {
    margin-left: 10px;
}

.socialbar .button img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: brightness(10);
}  

.gaugebar {
    position: fixed;
    font-family: 'Baloo 2';
    font-size: larger;

    animation: 1s ease-out 0s 1 slideInFromRight;
    user-select: none;
}

.gaugebar-top-right {
    top: 5px;
    right: 5px;
}

.gaugebar-bottom-right {
    bottom: 5px;
    right: 5px;
}

.profile {
    cursor: pointer;
    user-select: none;
}

.profile-picture {
    position: fixed;

    top: 10px;
    left: 10px;

    width: 120px;
    height: 120px;
    border: 5px solid #2b445e;
    border-radius: 60px;

    animation: 1s ease-out 0s 1 slideInFromLeft;
    z-index: 2;
}

.profile-picture img {
    width: 100%;
    height: 100%;

    border-radius: 60px;
}

.profile-data {
    position: absolute;

    left: 80px;
    top: 20px;

    background-color: var(--color-bg-primary-transparent);
    color: #ffffff;
    padding: 5px 10px 5px 55px;
    border-radius: 5px;
    font-family: 'Baloo 2';
    font-size: larger;

    animation: 1s ease-out 0s 1 slideInFromLeft;
}

.profile-data .rebel-count img {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    float: right;    
}

.profile-data .nickname .leaderboard-rank {
    background-color: #7990a9;
    border-radius: 5px;
    float: right;
    min-width: 38px;
    text-align: center;
    margin-left: 10px;
    padding: 0px 5px;
}

.profile-data .profile-game-xp {
    font-size: small;
    text-align: right;
    user-select: none;
}

.profile-data .profile-game-xp.profile-game-xp-updated {
    animation: 0.5s ease-out 0s 1 slideInFromBottom;
}

.profile-data .profile-game-energy {
    margin-bottom: 5px;
}

.profile-data .profile-game-level {
    position: relative;
    background-color: #048793;
    border: 3px solid #fff;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    float: left;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: medium;
    font-weight: bold;
}

.profile-data .profile-game-level.profile-game-level-updated {
    transform-origin: center center;
    transform: scale(2);
    background-color: #00acea !important;
    z-index: 5;
}

.profile-tickets {
    position: absolute;
    left: 20px;
    bottom: 20px;
    width: 80px;
    height: 80px;
    background-color: var(--color-bg-primary);
    border-radius: 50%;

    animation: 2s ease-out 0s 1 slideInFromLeft;
    z-index: 2001;
}

.profile-tickets img {
    position: relative;
    top: -10px;
    left: -10px;
    width: 100px;
    cursor: pointer;
}

.profile-tickets-rank {
    display: none;
    color: #ffffff;
    text-align: center;
    font-family: 'Baloo 2';
    font-size: xx-large;
    background-color: #ffffff55;
    width: 50px;
    padding: 4px;
    border-radius: 50%;
    margin: auto;
}

.landing-main .help {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;

    z-index: 2100;
}

.help {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #00000088;
}

.help .close-terminal {
    position: absolute;
    right: 20px;
    top: 22px;
    background-color: var(--color-bg-primary);
    color: #ffffff;
    padding: 2px 10px;
    border-radius: 5px;
    margin-right: 10px;
    font-weight: bold;
    cursor: pointer;
    opacity: 1;
    z-index: 2001;
}

.help .carousel, .help .carousel-inner, .help .carousel-item {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
    text-align: center;
}

.help img {
    max-width: 100%;
    max-height: 100%;
}

.landing-main .button-help {
    font-size: x-large;
}

@media screen and (max-width: 600px) {
    .statusbar {
        top: unset;
        bottom: 80px;
        font-size: large;
        left: 5%;
        right: 5%;
        display: none;
    }

    .waitingbar {
        top: 0px;
    }

    .gaugebar, .profile-picture, .profile-data {
        zoom: 0.7;
    }

    .versionbar {
        font-size: xx-small;
    }

    .socialbar {
        zoom: 0.7;
        justify-content: left;
    }

    .profile-tickets {
        zoom: 0.7;
        bottom: 80px;
        left: 10px;
    }
}