.base-game-app {
  position: absolute;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #000;
}

.base-game-app .gaugebar-top-right {
  top: 10px;
  right: 15px;
}

.base-app .close-terminal:has(+ .base-game-app) {
  left: 10px;
  right: unset;
  border-radius: 50%;
  top: 10px;
  background-color: #000;
}

.base-game-splash {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2100;
}

.base-game-splash-version {
  margin-top: 10px;
  padding: 2px 10px;
  border-radius: 5px;
  background-color: #fff;
  color: #000;
}

.world-selector {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  background-color: var(--color-bg-primary-transparent);
  background-color: transparent;
  border-radius: 0px;
  overflow: hidden;
  padding: 15px 15px;

  animation: 0.3s ease-out 0s 1 slideInFromTop;
  z-index: 2002;

  color: #fff;
}

.world-selector .container-fluid {
  padding: 15px;
  overflow-y: auto;
  height: 96%;
  margin-top: 2%;
  
  background-color: var(--color-bg-game);
  box-shadow: var(--color-bg-game-border-inner) 0 0 0 3px, var(--color-bg-game-border) 0 0 0 7px;
}

.world-selector .game-trivia {
  position: relative;
  width: 50%;
  height: unset;
  margin: auto auto;
}

.world-selector .game-trivia h3 {
  font-weight: bold;
}

.world-selector .game-trivia .game-trivia-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;  
}

.world-selector .game-trivia .game-trivia-buttons .btn {
  background-color: var(--color-bg-game-secondary);
  border-color: var(--color-bg-game-secondary);
}

.world-selector .container-fluid::-webkit-scrollbar {
  width: 1em;
  height: 1em;
}

.world-selector::-webkit-scrollbar {
  display: none;
}

.world-selector .container-fluid .token-list {
  justify-content: center;
  gap: 20px;
}

.world-selector .container-fluid .token-entry {
  border-radius: 0px;
  box-shadow: #ffffff 0 0 0 3px, #048d96 0 0 0 8px;
}

.world-selector .container-fluid .token-entry img {
  filter: grayscale();
  border-radius: 0px;
  border: 0px;
}

.world-selector .container-fluid .token-entry img:hover {
  filter: none;
}

.rebel-selector .token-list {
  justify-content: flex-start !important;
  margin-bottom: 20px;
}

.rebel-selector .token-entry-locked {
  filter: grayscale() brightness(50%);
}

.token-entry-costume {
  background-color: #00000088;
  image-rendering: pixelated;
  cursor: pointer;
  filter: grayscale();
}

.token-entry-costume:hover {
  filter: none;
}

.world-profile .profile-data {
  top: 7px;
}

.world-profile.profile-danger .profile-data {
  -webkit-animation: blink-danger-profile-data 1s infinite;
  -moz-animation: blink-danger-profile-data 1s infinite;
  -o-animation: blink-danger-profile-data 1s infinite;
  animation: blink-danger-profile-data 1s infinite;
}

@keyframes blink-danger-profile-data {
  0%, 49% {
    background-color: rgb(177, 24, 24);
  }
  50%, 100% {
    background-color:  var(--color-bg-primary-transparent);
  }
}

.world-time {
  user-select: none;
  font-size: small;
  text-align: right;
  white-space: nowrap;  
}

.world-points-awarded {
  position: absolute;
  width: 140px;
  height: 60px;
  right: 20px;
  top: 10px;
  font-size: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  background-color: #4f7f42;
  border-radius: 10px;
  text-align: center;
  font-weight: bold;

  z-index: 2500;
  --transform: scale(1.5, 1.5);
  animation: 0.2s ease-out 0s 1 slideInFromRight;
}

.world-points-awarded-negative {
  background-color: #a94436;
}

.world-points-awarded small {
  font-size: 0.5em;
  display: block;
  margin-top: -5px;  
}

.profile-level {
  font-size: small;
  text-align: right;
}

.game-options {
  position: fixed;
  top: 150px;
  left: 30px;
  bottom: 20px;
  width: 80px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  z-index: 2001;
  user-select: none;
}

.game-option {
  position: relative;
}

.game-option > div {
  width: 80px;
  height: 80px;
  border: 3px solid #2b445e;
  border-radius: 40px;
  background-color: #00000088;
  animation: 1s ease-out 0s 1 slideInFromLeft;
  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: center;  
  cursor: pointer;
  padding: 3px;
  user-select: none;
}

.game-options .world-selector {
  padding-top: 140px;
  padding-bottom: 0px;
}

.game-option.game-option-small > div {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  animation: none;
}

.game-option img {
  width: 80%;
  height: 80%;
}

.game-option .badge {
  position: absolute;
  font-size: medium;
  right: -10px;
  bottom: -5px;
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

.game-option-map-container {
  --display: flex;
  --justify-content: center;
}

.inventory-contents {
  position: absolute;
  left: 50px;
  right: 50px;
  bottom: 50px;
  top: 50px;
}

.world-selector .container-fluid.game-leaderboard {
  padding: 0;
}

.world-selector .container-fluid.game-leaderboard > .table-responsive {
  width: 100%;
}

.world-selector .container-fluid.game-leaderboard::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.world-selector .container-fluid.game-leaderboard .player-name
{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}  

.world-selector .container-fluid.game-leaderboard .orderby
{
  cursor: pointer;
}  

.world-selector .container-fluid .token-list.inventory {
  justify-content: flex-start;
}

.token-entry-inventory {
  display: flex;
  align-items: center;
  justify-content: center;  
  cursor: pointer;
  background-color: #00000088;
  filter: grayscale();
}

.token-entry-inventory > div {
  zoom: 4;
  image-rendering: pixelated;
}

.token-entry-inventory:hover {
  filter: none;
}

.token-entry-inventory.token-entry-selected {
  filter: none;
  background-color: #000000BB;
}

.token-entry-inventory-item-count,
.token-entry-inventory-player-count,
.token-entry-inventory-indicator-nft {
  zoom: 1 !important;
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #fff;
  color: #000;
  min-width: 30px;
  border-radius: 5px;
  text-align: center;
  font-size: medium;
  font-weight: bold;
  padding: 0px 2px;
}

.token-entry-inventory-player-count {
  top: unset;
  right: unset;
  bottom: 5px;
  left: 5px;
  background-color: #ffffff44;
  color: #fff;
}

.token-entry-inventory-indicator-nft {
  top: unset;
  bottom: 5px;
  background-color: #ffffff44;
  color: #fff;
}

.token-entry-inventory-item-label {
  margin-top: 12px;
  font-size: large;
  font-weight: bold;
  width: 100%;
  text-align: center;
  padding: 2px 5px;
  background-color: #fff;
  border-radius: 3px;
  color: #000;
  max-width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;  
}

.token-entry-inventory-details {
  background-color: #00000088;
  height: fit-content;
  width: 100%;
  padding: 5px 10px;
  margin-bottom: 20px;
}

.token-entry-inventory-details .title {
  border-bottom: 1px solid #fff;
  font-size: x-large;
}

.token-entry-inventory-details .actions {
  float: right;
  display: flex;
  flex-direction: row-reverse;
}

.token-entry-inventory-details .actions .button {
  height: 30px;
  min-width: 70px;
  padding: 2px 10px;
  border-radius: 3px;
  background-color: #fff;
  color: #000;
}

.token-entry-inventory-market {
  filter: unset;
}

.token-entry-inventory-market > div {
  width: 80%;
  height: 80%;
  padding: 10px;
  zoom: 1;
  image-rendering: auto;
}

.token-entry-inventory-market .button {
  width: 100%;
  height: 100%;
}

.world-selector .container-fluid .token-entry-inventory-market img,
.world-selector .container-fluid .token-entry-inventory-market img:hover {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: brightness(10);
}

.game-market {
  position: relative;
  overflow-y: hidden !important;
}

.game-market-header {
  padding: 10px 15px;
  z-index: 2;
  background-color: #00000088;
  max-height: 160px;
  box-shadow: #ffffff 0 0 0 3px, #048d96 0 0 0 8px;
}

.game-market-details {
  position: absolute;
  top: 170px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow-y: auto;
  padding: 15px 10px;
}

.game-market-details::-webkit-scrollbar {
  display: none;
}

.game-market .game-market-coins {
  position: absolute;
  right: 25px;
  background-color: #fff;
  border-radius: 3px;
  padding: 5px;
  color: #000;
  font-size: large;
  font-weight: bold;
}

.game-market .token-entry-inventory-details {
  height: fit-content;
  margin-top: 10px;
  background-color: transparent !important;
  box-shadow: none !important;
  border: 1px solid #fff;
  border-radius: 5px;
  margin-bottom: 0px;
}

.game-market .token-entry-inventory-details .title {
  display: none;
}

.token-entry-market {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.token-entry-market-buttons {
  display: flex;
  flex-direction: row;
  align-self: self-start;
  gap: 5px;
  margin-top: 5px;
  width: 165px;
}

.token-entry-market-buttons .btn {
  width: 80px;
  text-align: center;
  background-color: #00000055;
  border: 0;
  font-size: small;
  min-height: 52px;
  padding: 0;
}

.token-entry-market-buttons .btn:hover {
  background-color: #00000088;
}

.token-entry-market-buttons .btn:focus {
  border: 0;
  background-color: #00000088;
  box-shadow: none;
}

.world-selector .container-fluid .token-list.missions {
  justify-content: flex-start;
  padding: 10px;
}

.world-selector .token-list-title {
  margin-bottom: 20px;
  box-shadow: #ffffff 0 0 0 3px, #048d96 0 0 0 8px;
  border-bottom: 0;
  background-color: #00000088;
  color: #fff;
  padding: 5px 10px;
}

.world-selector .container-fluid .token-entry-mission {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  padding: 0px 10px;
  justify-content: flex-start;
  cursor: pointer;
  background-color: #ffffffAA;
  color: #000000;
  width: 30%;
  height: 99px;
  overflow: hidden;
}

.world-selector .container-fluid .token-entry-mission.token-entry-mission-collapsed {
  height: 50px;
}

.world-selector .container-fluid .token-entry-mission-2 {
  background-color: var(--bs-success);
  color: #ffffff;
}

.token-entry-mission .rewards {
  position: absolute;
  right: 10px;
  top: 5px;
  display: flex;
  flex-direction: column;  
}

.token-entry-mission .mission-message {
  border-top: 1px solid #fff;
  font-size: small;
  width: 100%;
  margin-top: 2px;
  padding-top: 5px;
}

.game-zoom-controls {
  position: absolute;
  bottom: 50px;
  right: 10px;
  z-index: 2000;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.game-joystick {
  position: absolute;
  bottom: 110px;
  right: 10px;
  background-color: #00000055;
  border-radius: 10px;
  width: 100px;
  height: 100px;
  z-index: 2000;
}

.game-joystick-button {
  position: absolute;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #ffffff22;
  user-select: none;
}

.game-joystick-button.activated-1 {
  background-color: #ffffffaa;
}

.game-joystick-button.activated-4 {
  background-color: #00ff0088;
}

.game-joystick-button-left {
  left: 5px;
  top: 40px;
}

.game-joystick-button-right {
  right: 5px;
  top: 40px;
}

.game-joystick-button-up {
  top: 5px;
  left: 40px;
}

.game-joystick-button-down {
  bottom: 5px;
  left: 40px;
}

.game-text-input {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 2000;
}

.game-text-input input, .game-text-input input:focus {
  border: 0px solid black;
  padding: 2px 5px;
  border-radius: 5px;
}

@media screen and (max-width: 900px) {
  .world-selector .container-fluid .token-entry-mission {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .game-options {
    zoom: 0.7;
  }
  
  .world-selector {
    padding: 20px;
    font-size: x-large;
  }

  .world-selector .container-fluid {
    padding: 15px;
    border-radius: 0px;
  }  

  .world-selector .container-fluid .token-entry {
    font-size: small;
  }  

  .world-selector .container-fluid .token-entry img {
    filter: none;
  }

  .token-entry-costume {
    filter: none;
  }
  
  .world-selector .container-fluid .token-entry-mission.token-entry-mission-collapsed {
    height: 40px;
  }

  .world-selector .token-list-title {
    font-size: medium;
  }

  .world-selector .game-trivia {
    width: 80%;
  }
  
  .world-points-awarded {
    width: 110px;
    height: 40px;
    right: 5px;
    top: 5px;
    font-size: 1em;
  }

  .base-game-app .gaugebar-top-right {
    right: 5px;
  }

  .base-app .close-terminal:has(+ .base-game-app) {
    left: 5px;
    top: 5px;
  }

  .token-entry-inventory > div {
    zoom: 3;
  }

  .token-entry-inventory-market > div {
    zoom: 1;
    image-rendering: auto;
  }

  .world-selector .container-fluid .token-entry-inventory-market img {
    filter: brightness(10);
  }

  .world-selector .container-fluid.game-leaderboard {
    font-size: medium;
  }  

  .game-market {
    font-size: medium;
    text-align: center;
    zoom: 0.8;
  }

  .game-market .token-list {
    justify-content: space-between !important;
  }  

  .rebel-selector .token-list {
    justify-content: center !important;
  }
}