@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@200&display=swap');

html {
  --color-primary: #fff;
  --color-bg-primary: #8247e5;
  --color-bg-primary-hover: #512d90;

  --color-terminal-bg-primary: rgb(40, 40, 40);
  --color-terminal-bg-header: rgb(80, 80, 80);
  --color-terminal-bg-title: rgb(60, 60, 60);

  --color-bg-primary: #4782e5;
  --color-bg-primary-border: #325899;
  --color-bg-primary-border-inner: #183362;
  --color-bg-primary-hover: #325899;
  --color-bg-primary-transparent: #000000BB;

  --color-bg-game: #00efd1;
  --color-bg-game-border:#048d96;
  --color-bg-game-border-inner:#fff;
  --color-bg-game-secondary: #00acea;

  --color-bg-secondary: #9823b9;
}

.modal-content {
  border-radius: 0.5rem;
  background-color: var(--color-bg-primary);
  color: var(--color-primary);
}  

.modal-header {
  color: var(--color-primary);
  background-color: var(--color-terminal-bg-title);
  height: 50px;
  border-radius: 0;
}

.link {
  cursor: pointer;
  color: var(--color-primary);
  text-decoration: none;
  padding: 4px;
  font-weight: bolder;
}

.link:hover {
  color:var(--color-primary);
  background-color: var(--color-bg-primary-hover);
  border-radius: 4px;
}

.button {
  cursor: pointer;
  background-color: var(--color-bg-primary);
  color: var(--color-primary);
  height: 60px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;

  text-decoration: none;
}

.button.button-reverse {
  background-color: var(--color-primary);
  color: var(--color-bg-primary);
}

.button:hover {
  background-color: var(--color-bg-primary-hover);
  color: var(--color-primary);
}

.button.button.button-reverse:hover {
  background-color: var(--color-bg-primary-hover);
  color: var(--color-primary);
}

.button img {
  width: 80%;
  height: 80%;
  object-fit: contain;
}

.button-home {
  position: fixed;
  top: 0px;
  left: 5px;
  z-index: 2000;
  width: 100px;
}

.button-home img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.button-modal {
  width: 200px;
  font-size: large;
}

.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

img.loading-rebel {
  background: url("css/images/resistance-fan.png");
}

.typewriter {
  font-family: Courier, monospace;
  display: inline-block;
}

.typewriter p {
  display: inline-block;
  overflow: hidden;
  letter-spacing: 2px;
  animation: typing 3s steps(30, end), blink .75s step-end infinite;
  white-space: nowrap;
  border-right: 2px solid var(--color-bg-primary);
  box-sizing: border-box;
}

@keyframes zoom {
  0% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(+100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(+100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes typing {
  from { 
      width: 0% 
  }
  to { 
      width: 100% 
  }
}

@keyframes blink {
  from, to { 
      border-color: transparent 
  }
  50% { 
      border-color: var(--color-bg-primary); 
  }
}

.nav-link {
  color: #ffffff80;
  text-transform: capitalize;
}

.nav-link:hover {
  color: #ffffff;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #fff;
  background-color: #ffffff50;
  border-color: #ffffff70 #ffffff70;
}

.tab-content .container-fluid {
  padding: 0;
}
.nav-item .badge {
  margin-left: 5px;
}

@media screen and (max-width: 600px) {
  .button-modal {
    width: 120px;
    font-size: small;
  }
}