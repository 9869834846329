/* Token Data */
.token-data-modal {
  width: 90vw;
  height: 90vh;
  max-width: unset;
  max-height: unset;
}

.token-data {
  width: 100%;
  height: 100%;
  padding: 20px;
  border: 1px solid var(--color-primary);
  border-radius: 10px;
}
  
.token-data .terminal {
  font-size: xx-large;
  padding: 10px;
  color: #fff;
  background-color: transparent;
  border-radius: 5px;
  font-size: x-large;
}

.token-data .token-points {
  position: absolute;
  --top: 80px;
  right: 20px;
  width: 30vw;
}

.token-data .token-points .token-point-data {
  float: right;
  font-weight: bold;
}

.token-data .token-details {
  position: relative;
}

.token-data .token-attrs-old {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex-wrap: wrap;
  width: 100%;
}

.token-data .token-attrs-old .token-attr {
  width: 100px;
  min-width: 60px;
  height: 100px;
  margin: 5px;
  border-radius: 10px;
  text-align: center;
  background-color: var(--color-terminal-bg-primary);
  color: var(--color-primary);
  padding: 3px;
}

.token-data .token-attrs-old .token-attr .attr-title {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #fff;
}

.token-data .token-attrs-old .token-attr .attr-value {
  font-weight: bold;
}

.token-data .token-attrs {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  flex-wrap: wrap;
  width: 100%;
  border: 1px solid;
  border-radius: 5px;
  background-color: var(--color-primary);
  color: var(--color-bg-primary);
}

.token-data .token-attrs .token-attr {
  width: 100%;
  min-width: 60px;
  background-color: var(--color-terminal-bg-primary);
  padding: 3px;
  position: relative;
  background-color: unset;
}

.token-data .token-attrs .token-attr + .token-attr {
  border-top: 1px solid;
}

.token-data .token-attrs .token-attr .attr-title {
  width: 40%;
  padding: 5px 10px;
}

.token-data .token-attrs .token-attr .attr-value {
  font-weight: bold;
  width: 60%;
  position: absolute;
  left: 40%;
  top: 3px;
  padding: 5px 10px;
  text-align: right;
}

.token-data .token-attrs .token-attr .levelup {
  background-image: url("./../../css/images/icons/level-up.png");
  background-size: cover;
  width: 28px;
  height: 28px;
  float: right;
  margin-left: 10px;
  margin-top: -4px;
  cursor: pointer;  
}

.token-data .token-attrs .token-attr .levelup:hover {
  filter: brightness(1.2);
}

.token-data .token-attrs-old .token-attr-Gen {
  background-color: var(--color-bg-secondary);
  color: var(--color-primary);
}

.token-data .token-attrs-old .token-attr-Points {
  background-color: var(--color-bg-secondary);
  color: var(--color-primary);
}

.token-data .token-attrs .token-attr:hover {
    --background-color: var(--color-bg-primary);
}

.token-data img {
  border-radius: 20px;
  width: 100%;
  object-fit: cover;
}  

.token-data .button-redeem {
  background-color: var(--color-bg-secondary);
  -position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  font-size: x-large;
  margin-top: 20px;
}

.redeem-modal .modal-content {
  background-color: var(--color-terminal-bg-primary);
  color: var(--color-primary);
}  

.redeem-modal .button-redeem {
  background-color: var(--color-bg-secondary);
  font-size: large;
  margin: auto auto;
}

.redeem-modal .redeemHash {
  overflow-wrap: break-word;
  font-size: small;
}

.rblb-modal .icon {
  height: 50px;
  --float: left;
  margin-right: 10px;
}

.rblb-modal h4 {
  padding: 0 30px;
  text-align: left;
}

.rblb-modal .balance {
  border-radius: 5px;
  background-color: var(--color-bg-secondary);
  padding: 2px 10px;
  margin-left: 5px;
  margin-right: 5px;
  float: right;
}

.rblb-modal .quantity-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 5px;
}

.rblb-modal .quantity-selector > .button-redeem-sm {
  font-size: medium;
  height: 40px;
}

.modal-rblb-modal {
  z-index: 2020;
}

.redeem-message {
  word-break: break-word; 
  max-height: 200px; 
  overflow: auto;
}

@media screen and (max-width: 600px) {
  .token-data-modal {
    margin: auto;
    width: 100vw;
  }
 
  .token-data h2 {
    text-align: center;
    margin: 10px;
  }

  .token-data .token-attrs {
    justify-content: center;
  }

  .token-data .token-attrs-old .token-attr {
    width: 80px;
    height: 80px;
    font-size: small;
  }

  .token-data .token-attrs .token-attr {
    font-size: small;
  }

  .token-data-modal .modal-content {
    border-radius: 0px;
  }

  .token-data {
    border-radius: 0px;
    padding: 0;
    border: 0;
  }

  .rblb-modal {
    zoom: 0.8;
  }
}