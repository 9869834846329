.world-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1);
  --transform: scale(2) translateY(13vh);
}

.world-container canvas {
  image-rendering: pixelated;
  width: 1920px;
  height: 1080px;
}

.world-status-container {
  position: absolute;
  --top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  color: #ffffff;

  pointer-events:none;  

  --animation-name: status-container-animation;
  --animation-duration: 6s;
  --animation-timing-function: ease;  
}

.world-message-container {
  position: absolute;
  top: 20vh;
  bottom: 20vh;
  left: 20vw;
  right: 20vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  cursor: pointer;

  font-family: 'Baloo 2';
  background-color: #000000CC;
  --background-color: #ffffffAA;
  --color: #000000;

  border-radius: 20px;
  animation: 0.5s ease-out 0s 1 zoom;
  z-index: 2010;
}

.world-message-container .badge {
  font-size: 1.25em;
}

.world-message-container.item-added-message {
  right: unset;
  bottom: unset;
  left: calc(50% - 100px);
  top: calc(50% - 100px);
  width: 200px;
  height: 200px;

  animation: 0.5s ease-out 0s 1 slideInFromTop;
}

.world-message-container.item-removed-message {
  right: unset;
  bottom: unset;
  left: calc(50% - 100px);
  top: 100px;
  width: 200px;
  height: 200px;

  animation: 0.5s ease-out 0s 1 slideInFromBottom;
}

.world-message-container.item-added-message > div,
.world-message-container.item-removed-message > div {
  zoom: 4;
  image-rendering: pixelated;
}

.world-message-status {
  position: absolute;
  top: 10px;
  left: 10px; 
  right: 10px; 
  margin-inline: auto; 
  width: fit-content;
  max-height: 200px;
  max-width: 90%;
  overflow: hidden;
  word-break: break-word;

  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  cursor: pointer;
  margin: auto auto;

  font-family: 'Baloo 2';
  background-color: #000000CC;

  border-radius: 20px;
  animation: 0.5s ease-out 0s 1 zoom;
  z-index: 2010;
}

.world-message-status-danger {
  background-color: var(--bs-danger);
}

.world-message-status-success {
  background-color: var(--bs-success);
}

@keyframes status-container-animation {
  0% { bottom: 50%; zoom: 1; background-color: #00000099; }
  25% { bottom: 50%; }
  50% { bottom: 0px; }
  100% { bottom: 0px; zoom: 1; background-color: transparent; }
}

.world-title {
  user-select: none;
  font-size: xx-large;
  font-weight: bold;
}

.world-subtitle {
  user-select: none;
  font-size: x-large;
}

.world-message {
  user-select: none;
  font-size: medium;
}

.world-message-container .world-title {
  margin-bottom: 5px;
  zoom: 1.5;
}

.world-message-container .world-subtitle {
  margin-bottom: 15px;
}

.world-message-container.mission-message {
  right: 20px;
  bottom: 20px;
  width: 400px;
  height: 200px;
  top: unset;
  left: unset;
}

.world-message-container.mission-message .world-title {
  font-size: x-large;
  font-weight: bold;
  zoom: 1;
  margin: 0;
}

.world-message-container.mission-message .world-subtitle {
  font-size: medium;
  margin: 0;
}

.world-message-container.mission-message .world-message {
  font-size: small;
}

.world-reaction-container {
  position: absolute;
  cursor: pointer;
  animation: 0.2s ease-out 0s 1 zoom;
  text-align: center;
  user-select: none;
}

.world-reaction-container .reaction-img {
  overflow: hidden;
  margin: auto;
  --transform: scale(2);
}

.world-reaction-container .reaction-img img {
  image-rendering: pixelated;
  object-fit: none;
}

.world-dialog-container {
  --bubble-backcolor: #ffffff;

  position: absolute;
  background-color: var(--bubble-backcolor);
  border-radius: 5px;
  font-family: 'Baloo 2';
  color: #000000;
  padding: 5px 10px;
  cursor: pointer;
  animation: 0.5s ease-out 0s 1 zoom;

  font-size: medium;
  max-width: 250px;
  max-height: 80px;
  user-select: none;  
  width: max-content;
    
  word-wrap: break-word;
}

.world-dialog-container::before {
  content: ''; 
  position: absolute;
  background-color: var(--bubble-backcolor);
  width: 25px;
  height: 15px;
  bottom: -14px;
  clip-path: polygon(25% 0, 100% 0, 0% 100%);  
}

.world-dialog-container .character-img {
  margin-top: 2px;
  
  width: 32px;
  height: 32px;
  overflow: hidden;
  zoom: 3;
  image-rendering: pixelated;
  float: left;
}

.world-dialog-container .character-img img {
  margin-left: -8px;
  margin-top: -6px;
}

.world-dialog-container.player {
  --bubble-backcolor: rgb(83 108 217);
  background-color: var(--bubble-backcolor);
  color: #fff;
}

.world-character {
  border: 2px solid blue;
  width: 48px;
  height: 48px;
  border: 0px solid white;
  cursor: pointer;
  position: fixed;
}

@media screen and (max-width: 1200px) {
  .world-dialog-container {
    max-width: 200px;
    max-height: 70px;
    font-size: small;
  }
}

@media screen and (max-width: 768px) {
  .world-status-container {
    zoom: 0.7;
    align-items: baseline;
  }

  .world-message-container {
    top: 0vh;
    bottom: 0vh;
    left: 0vw;
    right: 0vw;
    border-radius: 0;
    zoom: 0.8;
  }

  .world-message-container.item-added-message, 
  .world-message-container.item-removed-message {
      border-radius: 20px;
  }  

  .world-dialog-container {
    width: unset;
    max-width: 200px;
    max-height: 90px;
    font-size: small;
  }

  .world-message-container.mission-message {
    left: 5%;
    right: 5%;
    bottom: 20px;
    width: 90%;
    height: 200px;
    top: unset;
    left: unset;
  }

  @media screen and (min-height: 760px) {
  }
}

@media screen and (max-width: 400px) {
  .world-dialog-container {
    max-width: 160px;
    max-height: 1100px;
    font-size: small;
    margin-left: -20px;
    margin-top: -30px;
  }
}