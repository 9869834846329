.invasion-maze-app {
    position: absolute;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: #000;
}

.invasion-maze-app .gaugebar-top-right {
    top: 50px;
    right: 15px;
}
  
.invasion-maze-app .gaugebar-bottom-right {
    bottom: 15px;
    right: 10px;
}
  
.stage-title {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    top: 15px;
    font-size: xx-large;
}

.maze-list {
    position: relative;
    width: 100%;
    margin: auto;
    margin-top: 10%;
    background-color: transparent;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    list-style: none;
    animation: 1s ease-out 0s 1 slideInFromTop;
    flex-wrap: wrap;
    gap: 10px;
}

.maze-entry {
    position: relative;
    text-align: center;
    cursor: pointer;
    padding: 5px;
    border-radius: 20px;
    height: 30vh !important;
    width: 30vw;      
}

.maze-entry h2 {
    font-size: small;
}

.maze-entry img {
    width: 200px;
    height: 200px;
    object-fit: contain;
    opacity: 1;
}

.maze-entry img:hover {
    object-fit: contain;
}

.maze-entry-title {
    margin-top: 10px;
    font-size: x-large;
    font-weight: bold;
}
  
.maze-entry-disabled, .maze-entry-disabled:hover {
    filter: brightness(1.2) grayscale();
    cursor: default;
}
 
.maze-editor-map {
    display: flex;
    flex-direction: row;
    width: 960px;
    height: 576px;
    flex-wrap: wrap;
    zoom: 1;
    margin: auto;
}

.maze-editor-cell {
    border: 1px solid green;
    width: 48px;
    height: 48px;
    flex-grow: 1;
    cursor: pointer;
}

.maze-editor-cell.cell-ground {
    background-color: green;
}

@media screen and (max-width: 600px) {
    .maze-list {
        margin-top: 15%;
    }

    .maze-entry {
        height: 20vh !important;
    }
}