.champion-builder {
    position: relative;
    --width: calc(100vw - 20px);
    --height: calc(100vh - 20px);
    max-width: 1000px;
    height: 95vh;
    margin: auto;
    margin-top: 2.5vh;
    background-color: #ffffff;
    opacity: 1;
    border-radius: 10px;
    border: none;

    background-image: url("./../css/images/battle/champion-base/champions-background.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.champion-builder .title {
    text-align: center;
    font-size: xx-large;
    font-weight: bold;
    color: #ffffff;
    font-size: 5vw;
    background-color: rgba(0, 0, 0, 0.3);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.champion-builder .subtitle {
    text-align: center;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.3);
    padding-bottom: 10px;
}

.champion-builder .skin-selector {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 25px;
}

.skin {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    cursor: pointer;
    --border: 3px solid black;
}

.skin.selected, .skin:hover {
    transform: scale(1.3);
}

.trait-selector-list {
    list-style: none;
    padding-top: 15px;
    padding-left: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
}

.trait-selector-entry {
    width: 74px;
    height: 74px;
    padding: 5px;
    line-height: 64px;        

    filter: grayscale();
    cursor: pointer;
    transform: scale(0.7);

    text-align: center;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    overflow-y: hidden;    
}

.trait-selector-entry img {
    width: 64px;
    height: 64px;
}

.trait-selector-entry:hover, .trait-selector-entry.selected {
    transform: scale(1.1);
    filter: none;
}

.trait-selector-entry.selected {
    background-color: #00000022;
    border-radius: 10px;
}

.gender-selector {
    position: relative;
    width: 90%;
    height: calc(100% - 280px);
    margin: auto;
    border: none;
}

.champion-gender {
    position: absolute;
    left: 70%;
    top: 0;

    cursor: pointer;
    height: 100%;
    width: 25%;
    opacity: 1;
    max-height: 25vh;

    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    transform: scale(0.9);
    filter: grayscale();
    opacity: 0.7;
}

.champion-gender:hover {
    --filter: none;
}

.champion-gender.champion-selected {
    filter: none;
    left: 0;
    width: 80%;
    max-height: 100vh;
    opacity: 1;
}

.selected-champion.champion-female,
.selected-champion.champion-male {
    left: 20%;
    width: 60%;
}

.selected-champion.champion-gender,
.selected-champion.champion-gender:hover {
    transform: scale(1);
}

.champion-customize, .champion-customize-background {
    position: relative;
    width: 90%;
    height: calc(100% - 300px);
    margin: auto;
    --border: 1px solid green;    
    border-radius: 20px;
    background-color: #ffffff33;
}

.champion-builder .footer {
    position: absolute;
    bottom: 7px;
    left: 7px;
    right: 7px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.champion-builder .footer .button {
    background-color: #ac9045;
    width: 150px;
    font-size: xx-large;
}

.champion-builder .footer .button:hover {
    background-color: #846d2e;
}

.champion-builder .footer .button-text {
    text-align: center;
    font-size: large;
}

.champion-attributes {
    position: relative;
    background-color: #ffffffcc;
    padding: 5px 15px;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    --margin: 10%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    z-index: 2000;
}

.champion-attributes div {
    background-color: var(--color-bg-primary);
    padding: 2px 15px;
    height: 30px;
    border-radius: 10px;
    color: #ffffff;
    width: fit-content;
    margin: 0 auto;
}

.champion {
    position: absolute;
    left: 0;
    top: 0;

    cursor: pointer;
    height: 100%;
    width: 100%;
    opacity: 1;
}

.champion .champion-trait-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-position: center top;
    background-size: contain;
    background-repeat: no-repeat;
    background-color:transparent;    
}

@media screen and (max-width: 600px) {
    .champion-builder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        margin: unset;
        border-radius: 0;
    }

    .champion-builder .title {
        border-radius: 0;
    }

    .skin.selected, .skin:hover {
        transform: scale(1.2);
    }

    .trait-selector-list {
        padding-top: 10px;
        gap: 10px;
    }
    
    .trait-selector-entry {
        width: 55px;
        height: 55px;
        line-height: 45px;        
    }

    .trait-selector-entry img {
        width: 45px;
        height: 45px;
    }

    .champion-builder .footer .button {
        --width: 60px;
        height: 50px;
        font-size: x-large;
    }

    .champion-builder .footer .button-text {
        text-align: center;
        font-size: medium;
    }
    
    .champion-customize {
        position: relative;
        width: 95%;
        height: calc(100% - 250px);
    }    

    .champion-builder .title {
        font-size: 6vh;
    }

    .champion-gender {
        max-height: 35vh;
        width: 40%;
        left: 60%;
    }
}

@media screen and (max-width: 1000px) {
    .gender-selector {
        height: calc(100% - 260px);
    }    
}

@media screen and (min-width: 1400px) {
    .gender-selector {
        height: calc(100% - 350px);
    }    
}