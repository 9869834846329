
.base-app {
  color: #ffffff;
  background-color: #ffffff;
  width: 100vw;
  height: 100vh;

  background-color: #7eaddd;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
}

.base-app .close-terminal {
  position: absolute;
  right: 10px;
  top: 15px;
  background-color: var(--color-bg-primary);
  color: #ffffff;
  padding: 2px 10px;
  border-radius: 5px;
  margin-right: 10px;
  font-weight: bold;
  cursor: pointer;
  z-index: 2001;
}

.base-app > h1 {
  padding: 10px 10px;
  border: 0px;
  font-size: xx-large;
  font-weight: bold;
  color: var(--color-primary);
  background-color: #00000066;
  border-radius: 10px;
}

@media screen and (max-width: 600px) {
  .base-app .close-terminal {
    right: 0px;
    top: 10px;
  }

  .base-app > h1 {
    font-size: x-large;
    border-radius: 0;
  }
}