.gauge {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    justify-items: center;
}

.gauge + .gauge {
    margin-top: 2px;
}

.gauge .icon {
    width: 55px;
    height: 55px;
    margin-right: -28px;
    z-index: 2;
}

.gauge .value-container {
    padding: 10px 5px;
}

.gauge .value {
    background-color: var(--color-bg-primary-transparent);
    text-align: left;
    
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    padding: 5px 20px 5px 35px;
    color: #ffffff;
}

.gauge-clickable {
    cursor: pointer;
}