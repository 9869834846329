.paycheck {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #00000055;
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 2000;
}

.paycheck-container {
  position: relative;
  width: 700px;
  height: 350px;
  background-color: #ffffff;
  padding: 10px;
  animation: 1s ease-out 0s 1 slideInFromTop;  
}

.paycheck-watermark {
  background-image: url("./css/images/logo.png");
  background-position: center center;
  background-size: auto;
  filter: grayscale();

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.05;
  font-size: 4em;
  text-align: center;
  font-weight: bolder;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.paycheck .paycheck-container .close-window {
  position: relative;
  float: right;
  cursor: pointer;
  margin-right: 15px;
  margin-top: 5px;
  font-weight: bold;
  z-index: 2001;
}

.paycheck-content {
  position: relative;
  border: 3px solid #000000;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  padding: 10px;
  font-size: x-large;
}

.paycheck-content img {
  width: 400px;
  float: right;
}

.paycheck-content .points {
  text-align: center;
  font-size: xx-large;
  font-weight: bold;
}

.paycheck-content .collect {
  position: absolute;
  padding: 10px;
  border: 2px solid #000000;
  width: 110px;
  text-align: center;
  border-radius: 5px;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
}

.paycheck-content .collect:hover {
  background-color: #00000045;
}

@media screen and (max-width: 600px) {
  .paycheck-content {
    font-size: large;
  }
}
