.rebel-factory {
    color: #ffffff;
    background-color: #ffffff;
    width: 100vw;
    height: 100vh;

    background-image: url("./../css/images/banner/banner-rebel-factory.png");
    background-position: center center;
    background-size: cover;
}

.rebel-factory .machine {
    margin: auto;
    opacity: 0.99;
    font-family: unset;
    background-color: #000000cc;
    border: 0;
}

.rebel-factory hr {
    opacity: 1;
}

.rebel-factory .component-factory > h1 {
    padding: 10px 10px;
    border: 0px;
    font-size: xx-large;
    font-weight: bold;
    color: var(--color-primary);
    background-color: #00000066;
    border-radius: 10px;
}

.rebel-factory .machine .machine-display {
    border-radius: 0;
    border: 0;
    color: #ffffff;
}

.rebel-factory .machine .machine-mint-button {
    border: 0;
    border-radius: 5px;
}

.rebel-factory .machine .machine-mint-button:hover {
    background-color: #972424;
}

.machine .machine-monitor {
    min-height: 130px;
    height: unset;
}

.rebel-factory .button-connect {
    margin: auto;
    margin-top: 10px;
}

@media screen and (max-width: 600px) {
    .rebel-factory .component-factory h1 {
        font-size: x-large;
        border-radius: 0;
    }

    .rebel-factory .machine {
        border-radius: 0;
        border: 0;
    }
}
