
.the-resistance {
  color: #ffffff;
  background-color: #ffffff;
  width: 100vw;
  height: 100vh;

  background-image: url("./../css/images/banner/banner-background.png");
  background-position: center center;
  background-size: cover;
}

.the-resistance-container {
  width: 100%;
  height: calc(100% - 60px);
  overflow: auto;
}

.the-resistance .resistance-hierarchy {
  position: relative;
  display: flex;
  list-style: none;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
  justify-content: center;
}

.the-resistance .resistance-count {
  float: right;
  margin-right: 55px;
}

.the-resistance .resistance-hierarchy > li {
  position: relative;
  cursor: pointer;
}

.the-resistance .resistance-hierarchy > li > img {
  width: 150px;
  height: 150px;
  border-radius: 15px;
}

.the-resistance .resistance-hierarchy > li.pending > img {
  filter: grayscale() brightness(0.7);
}

.the-resistance .resistance-hierarchy > li.pending:hover > img {
  filter: unset;
}

.the-resistance .resistance-hierarchy > li > .level {
  left: -8px;
  top: -5px;
  background-image: url("./../css/images/icons/shield.png");
  background-size: cover;
  position: absolute;
  width: 40px;
  height: 40px;
  text-align: center;
  padding-top: 10px;
  font-size: small;
  cursor: pointer;
  font-weight: bold;
}

.the-resistance .resistance-hierarchy > li > .rarity {
  right: 6px;
  bottom: 5px;
  position: absolute;
  background-color: #ffffffaa;
  border-radius: 5px;
  padding: 2px;
  color: #000;
  font-weight: bold;
  font-size: small;
  z-index: 1000;
  cursor: default;
}

.the-resistance .resistance-hierarchy > li > .level:hover {
  zoom: 1;
}

.the-resistance .resistance-hierarchy > li {
  margin: 5px;
}

@media screen and (max-width: 600px) {
  .the-resistance .resistance-hierarchy > li > img {
    width: 70px;
    height: 70px;
    border-radius: 7px;
  }

  .the-resistance .resistance-hierarchy > li > .level {
    width: 30px;
    height: 30px;
    padding-top: 6px;
    font-size: x-small;
  }  
  
  .the-resistance .resistance-hierarchy > li > .rarity {
    font-size: x-small;
    right: 2px;
    bottom: 2px;
  }  

  .the-resistance .resistance-count {
    font-size: large;
    margin-right: 35px;
    margin-top: 5px;
  }  
}