.daily-rewards {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #00000055;
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 1000;
}

.daily-rewards .rewards .close-window {
  float: right;
  cursor: pointer;
}

.daily-rewards .rewards {
  position: absolute;
  width: 60vw;
  height: 600px;
  background-color: var(--color-bg-primary);
  background-color: #000000DD;
  color: var(--color-primary);
  padding: 20px;
  border-radius: 10px;
  min-width: 600px;
  max-width: 600px;
}

.daily-rewards .rewards h2 {
  text-align: center;
  border-bottom: 1px solid var(--color-primary);
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.daily-rewards .rewards .days {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.daily-rewards .rewards .days .reward {
  position: relative;
  width: 150px;
  height: 150px;
  border: 1px solid var(--color-primary);
  border-radius: 5px;
  background-color: #335b95;
  padding: 5px 10px;
  opacity: 0.6;
}

.daily-rewards .rewards .days .reward.enabled {
  cursor: pointer;
  background-color: var(--color-bg-secondary);
  opacity: 1;
}

.daily-rewards .rewards .days .reward small {
  float: left;
}

.daily-rewards .rewards .days .reward img {
  position: absolute;
  width: 70%;
  height: 70%;
  top: 20%;
  left: 15%;
  filter: grayscale();
}

.daily-rewards .rewards .days .reward.enabled img {
  filter: unset;
}

.daily-rewards .rewards .days .reward .points {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.daily-rewards .rewards .days .reward .points > div {
  width: 100%;
  font-size: x-large;
  font-weight: bold;
}

.daily-rewards .rewards .roulette {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  gap: 10px;  
  height: calc(100% - 50px);
}

.daily-rewards .rewards .roulette .result {
  font-size: x-large;
}

.daily-rewards .rewards .roulette .result .result-points {
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #fff;
  color: #000;
  font-weight: bold;
  margin: 0 3px;
}

@media screen and (max-width: 600px) {
  .daily-rewards .rewards {
    min-width: unset;
    width: 95vw;
    height: 400px;
  }

  .daily-rewards .rewards .days .reward {
    width: 90px;
    height: 90px;
  }

  .daily-rewards .rewards .days .reward img {
    width: 60%;
    height: 60%;
    top: 30%;
    left: 20%;
  }

  .daily-rewards .rewards .roulette canvas {
    width: 250px;
    height: 250px;
  }
}
