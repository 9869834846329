.navbar {
  overflow: hidden;
  position: fixed; 
  top: 0px; 
  left: 40px;
  right: 10px;
  padding: 5px 10px;
  z-index: 1010;

  display: flex;
  flex-direction: row-reverse;
  align-items: unset;
  justify-content: unset;
}

.navbar .button {
  width: 60px;
  height: 60px;
}

.navbar .button+.button {
  margin-right: 10px;
}

.navbar .button img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: brightness(10);
}

#btn-discord-profile {
  background-color: transparent;
}

#btn-discord-profile img {
  filter: grayscale();
  border-radius: 30px;
}

#btn-discord-profile:hover img {
  filter: none;
}

@media screen and (max-width: 800px) {
  .navbar .button {
    height: 40px;
    width: 40px;
  }

  .navbar {
    left: 30px;
    right: 0;
  }
}