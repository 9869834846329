.roach-counter {
    position: absolute;
    left: 10px;
    top: 10px;
    padding: 5px 10px;
    background-color: var(--color-bg-primary-transparent);
    font-family: 'Baloo 2';
    border-radius: 12px;
    font-size: large;
}

@media screen and (max-width: 600px) {
    .roach-counter {
        zoom: 0.7;
    }
}