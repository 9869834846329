.battle-eth .token-list {
    display: flex;
    list-style: none;
    padding: 0px;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 0px;
}

.battle-eth .token-list-title {
  padding-bottom: 3px;
  border: 0px;
  margin-top: 15px;
  font-size: xx-large;
  font-weight: bold;
  color: var(--color-primary);
  background-color: #ffffff33;
  border-radius: 10px;
}

.battle-eth .token-list .token-entry img {
    width: 150px;
    height: 150px;
    border-radius: 15px;
    filter: grayscale();
    cursor: pointer;
    border: 5px solid transparent;
}

.battle-eth .token-list .token-entry {
  position: relative;
  border-radius: 15px;
  border: 1px solid var(--color-primary);
  width: 150px;
  height: 150px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.battle-eth .token-list-md .token-entry img {
  width: 120px;
  height: 120px;
}

.battle-eth .token-list-md .token-entry {
  width: 120px;
  height: 120px;
}

.battle-eth .token-list-sm .token-entry img {
  width: 70px;
  height: 70px;
}

.battle-eth .token-list-sm .token-entry {
  width: 70px;
  height: 70px;
  border: 0px;
}

.battle-eth .token-list .token-entry .token-entry-locked.token-entry-captured {
  background-color: green;
}

.battle-eth .token-list .token-entry .token-entry-locked {
  position: absolute;
  border-radius: 15px;
  width: 150px;
  height: 150px;
  font-size: medium;
  opacity: 0.5;
  background-color: #ffffff;
  color:#000;
  z-index: 10;
  display: flex;
}

.battle-eth .token-list .token-entry .token-entry-locked * {
  width: 60%;
  height: 60%;
  margin: auto;
}

.battle-eth .token-list .token-entry .token-entry-requirements {
  position: absolute;
  top: -5px;
  left: -5px;
  font-weight: bold;
  color: #FFF;
  background-color: #000;
  border-radius: 10px;
  padding: 2px 10px;
  z-index: 1000;
  cursor: pointer;
}

.battle-eth .token-list .token-entry .token-entry-requirements img {
  width: 25px;
  height: 25px;
  border: 0;
  filter: none;
  margin-right: 5px;
}

.battle-eth .token-list .token-entry .token-entry-available {
  position: absolute;
  bottom: -5px;
  right: -5px;
  height: 30px;
  font-weight: bold;
  color: #FFF;
  background-color: #000;
  border-radius: 10px;
  padding: 2px 10px;
  z-index: 1000;
}

.battle-eth .token-list .token-entry.token-selected img, 
.battle-eth .token-list .token-entry img:hover {
    filter: none;
}

.battle-eth .token-list .token-entry .badge {
  position: absolute;
  --width: 30px;
  height: 30px;
  font-size: medium;
}

.battle-eth .token-list .token-entry .level {
  left: -8px;
  top: -5px;
  background-image: url("./../css/images/icons/shield.png");
  background-size: cover;
  position: absolute;
  width: 40px;
  height: 40px;
  text-align: center;
  padding-top: 10px;
  font-size: small;
  cursor: pointer;
  font-weight: bold;
}

.battle-eth .token-list .token-entry .level:hover {
  zoom: 1;
}

.battle-eth .token-list .token-entry .levelup {
  right: 5px;
  bottom: 5px;
  background-image: url("./../css/images/icons/level-up.png");
  background-size: cover;
  position: absolute;
  width: 40px;
  height: 40px;
  text-align: center;
  padding-top: 10px;
  font-size: small;
  cursor: pointer;
  font-weight: bold;
}

.battle-eth .token-list .token-entry .levelup:hover {
  zoom: 1.3;
}

.battle-eth .token-list .token-entry .badge.voucher {
  right: -5px;
  top: -5px;
}

.battle-eth .token-list .token-entry .badge.balance {
  right: -5px;
  bottom: -5px;
  z-index: 999;
}

.battle-eth .token-list .token-entry .card-title,
.battle-eth .token-list .token-entry .card-stats {
  display: none;
}

.battle-eth .token-list .token-entry.token-entry-card {
  position: relative;
  width: 200px;  
  height: 280px;
  background-color: #3361a3;
  cursor: pointer;
  padding: 0;
  border: 0;
  filter: brightness(0.85);
}


.battle-eth .token-list .token-entry.token-entry-card:hover {
  filter: brightness(1);
}

.battle-eth .token-list .token-entry.token-entry-card .card-title {
  position: relative;
  display: inline-block;
  font-size: medium;
  text-align: center;
  width: 100%;
  color:#fff;
  padding: 5px 10px 5px 10px;
  font-weight: bold;
  bottom: 0px;
}

.battle-eth .token-list .token-entry.token-entry-card .image {
  border-radius: 15px;
  background-color: #3361a3;
}

.battle-eth .token-list .token-entry.token-entry-card .image img {
  position: relative;
  width: 100%;
  height: auto;
  filter: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;  
  border: 0;
  --border-bottom: 7px solid #16468a;
  z-index: 1;
}

.battle-eth .token-list .token-entry.token-entry-card .card-stats {
  position: absolute;
  display: flex;
  width: 50px;
  right: 0px;
  top: 50px;
  bottom: 0px;
  background-color: #FFF;
  color: #3361a3;
  border-bottom-right-radius: 10px;
  flex-flow: column;
  justify-content: flex-end;
  padding: 5px 2px;
  gap: 5px;
}

.battle-eth .token-list .token-entry.token-entry-card .defense {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 1px 10px 3px;
  background-color: #3361a3;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 15px;
  font-size: large;  
  z-index: 2;
}

.battle-eth .token-list .token-entry.token-entry-card .attack {
  position: absolute;
  left: 0px;
  top: 0px;
  padding: 1px 10px 3px;
  background-color: #3361a3;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 15px;
  font-size: large;  
  z-index: 2;
}

.battle-eth .token-list .token-entry.token-entry-card .card-stats > div {
  font-size: x-large;  
}

.battle-eth .token-list .token-entry.token-entry-card .card-stats > div + div {
  border-top: 1px solid #3361a3;
}

.battle-eth .token-list-buttons {
  float: right;
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 3px;
}

.battle-eth .token-list-button {
  cursor: pointer;
}

.battle-eth .token-list-button + .token-list-button {
  margin-right: 10px;
}

.battle-eth .token-list-empty {
  color: var(--color-primary);
}

.battle-eth .token-list .token-entry .spinner {
  margin-left: 30%;
  margin-top: 30%;
  width: 40%;
  height: 40%;
}

@media screen and (max-width: 600px) {
  .battle-eth .token-list-title {
    margin-top: 0px;
    border-radius: 0px;
  }

  .battle-eth .token-list .token-entry, .battle-eth .token-list {
    justify-content: center;
  }

  .battle-eth .token-list .token-entry, .battle-eth .token-list .token-entry img {
    width: 120px;
    height: 120px;
  }

  .battle-eth .token-list .token-entry .token-entry-locked {
    width: 120px;
    height: 120px;
  }  

  .battle-eth .token-list-sm .token-entry, .battle-eth .token-list-sm .token-entry img {
    width: 56px;
    height: 60px;
  }

  .battle-eth .token-list .token-entry .token-entry-requirements img {
    width: 15px;
    height: 15px;
  }  

  .battle-eth .token-list .token-entry .token-entry-requirements span, .battle-eth .token-list .token-entry .token-entry-available {
    font-size: x-small;
  }

  .battle-eth .token-list .token-entry .level {
    width: 30px;
    height: 30px;
    padding-top: 8px;
    font-size: x-small;
  }

  .battle-eth .token-list .token-entry .levelup {
    width: 40px;
    height: 40px;
  }

  .battle-eth .token-list .token-entry img {
    filter: unset;
  }

  .battle-eth .token-list .token-entry.token-entry-card {
    zoom: 0.8;
    filter: brightness(1);
  }  
}