.pick-game .coming-soon, .pick-game .help {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;

    z-index: 1000;
}

.pick-game .help {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #00000066;
}

.pick-game .help img {
    cursor: pointer;
    width: auto;
    max-width: 80%;
    max-height: 100%;    
}

.coming-soon {
    background-color: #ffffff;
    opacity: 0.5;
}

.coming-soon-text {
    position: absolute;
    text-align: center;
    background-color: var(--color-bg-secondary);
    color: #ffffff;
    width: 350px;
    bottom: 50px;
    left: calc((100vw - 350px) / 2);
    font-family: 'Baloo 2';
    font-size: 4em;
    border-radius: 10px;
    padding: 10px;
    z-index: 1010;
}

.pick-game {
    position: absolute;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
}

.pick-game:before {
    content: ' ';
    opacity: 0.6;
    position: absolute;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    --background-image: url("./../css/images/banner/banner-battle-for-eth.png");
    background-position: center center;
    background-size: cover;
}  

.pick-game .coming-soon {
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    padding: 10px 20px;
    z-index: 2000;
    margin: auto;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
    font-size: 50px;
    background-color: var(--color-bg-primary);
    border-radius: 10px;
    margin: auto auto;
    cursor: default;
    opacity: 0.6;
    display: flex;
    flex-flow: column;
    justify-content: center;    
}

.pick-game .button-connect {
    top: 10px;
    position: fixed;
    left: calc(50% - 75px);
    width: 150px;
    font-size: large;
}

@media screen and (max-width: 600px) {
    .pick-game .help img {
        width: 95%;
        max-width: 95%;
    }
}
