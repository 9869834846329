.battle-eth {
    position: absolute;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
}

.battle-eth-background-image {
    content: ' ';
    opacity: 0.1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-image: url("./../css/images/banner/banner-battle-for-eth.png");
    background-position: center center;
    background-size: cover;
    pointer-events: none;    
}  

.battle-eth .alert {
    z-index: 2100;
    position: absolute;
    width: 100%;
    border-radius: 0;
}

.battle-eth .close-terminal {
    position: fixed;
}

.battle-eth .wait {
    text-align: center;
}

.battle-eth .coming-soon {
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    padding: 10px 20px;
    z-index: 2000;
    margin: auto;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
    font-size: 50px;
    background-color: var(--color-bg-primary);
    border-radius: 10px;
    margin: auto auto;
    cursor: default;
    opacity: 0.85;
}

.battle-eth .coming-soon-baby {
    position: absolute;
    top: 10vh;
    bottom: 10vh;
    left: 0px;
    width: 100vw;
    height: 80vh;
    text-align: center;
    z-index: 2001;
}

.battle-eth .coming-soon-baby img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

.battle-eth .top-menu {
    padding: 10px;
    gap: 10px;
}

.battle-eth .menu-list {
    position: fixed;
    background-color: rgba(60, 60, 60, 0.7);
}

.menu-list.is-nav-open + .battle-eth-container {
    position: absolute;
    left: 80px;
    right: 0px;
    bottom: 0px;
    top: 0px;
    transition: right 1s;
}

.battle-eth-container .title {
    position: fixed;
    padding: 7px 10px;
    border: 0px;
    width: 100%;
    top: 0px;
    font-size: xx-large;
    font-weight: bold;
    color: var(--color-primary);
    background-color: #ffffff33;
    border-radius: 0px;
    margin-bottom: 20px;
    margin-top: -2px;
    z-index: 1800;
}

.battle-eth-container .title + div {
    animation: 0.5s ease-out 0s 1 slideInFromBottom;
}

.battle-eth-container .battle-eth-contents {
    margin-top: 80px;
}

.battle-eth-container .button-connect {
    margin-left: 10px;
}

.battle-eth-container .refresh-data {
    position: relative;
    width: 30px;
    height: 30px;
    margin-left: 10px;
    cursor: pointer;
    padding: 4px;
    border-radius: 40%;
    background-color: #3361a3;
    vertical-align: middle;
    display: inline-flex;
    justify-content: center;    
    align-items: center;
    filter: brightness(1.3);
}

.battle-eth-container .refresh-data:hover {
    filter: brightness(1);
}

.leaderboard {
    padding: 0 10px;
}

.champion-list, .battle-list, .market-list {
    position: relative;
    top: 0px;   
    padding: 0px 10px; 
    --background-color: #ffffff33;
    margin-bottom: 10px;
}

.market-list .market-legend {
    padding: 0px 0px;
    margin-bottom: 15px;
    font-size: large;
}

.minting-screen {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    padding: 10px 20px;
    border-radius: 20px;
    background-color: #00000077;
    z-index: 2002;
}

.minting-screen h2 {
    padding-bottom: 10px;
    border-bottom: 1px solid #fff;
}

.minting-screen .content {
    font-size: x-large;

    display: flex;
    flex-direction: column;
    align-content: center;
}

.minting-screen a {
    font-size: medium;
    color: #fff;
}

.minting-screen .spinner {
    margin-right: 10px;
}

.minting-screen .error {
    font-size: medium;
    border: 1px solid #fff;
    padding: 10px;
    border-radius: 10px;
    background-color: #ffffff44;
    color: #fff;
    overflow: hidden;
}

.minting-screen .hash {
    font-size: medium;
    border: 1px solid #fff;
    padding: 5px;
    border-radius: 10px;
    background-color: #ffffff44;
    color: #fff;
    overflow: hidden;
}

.minting-screen .button {
    width: 150px;
    margin-top: 15px;
}

.asset-data,
.champion-data, 
.battle-data,
.asset-data {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    --bottom: 20px;
    padding: 20px;
    border-radius: 20px;
    background-color: #00000099;
    margin: auto auto;
}

.asset-data {
    max-width: 1200px;
}

.battle-data {
    max-width: 600px;
}

.champion-header {
    position: relative;
    display: flex;
    height: 200px;
    margin-bottom: 20px;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.champion-header .stats {
    display: none;
}

.champion-header img {
    width: unset !important;
    height: 100%;
}

.champion-header .token-attr {
    width: 80% !important;
    margin-left: 10px;
    border: 0 !important;
}

.battle-banner img,
.battle-data img,
.champion-data img,
.asset-data img {
    width: 100%;
    border-radius: 20px;
}

.data-actions {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 10px;
}

.data-actions button {
    width: 150px;    
    text-align: center;
    font-size: large;
}

.battle-banner-splash {
    position: absolute;    
    --background-color: #00000077;
    padding: 30px 0px;
    z-index: 2200;
    width: 100%;
    height: auto;
    --top: 25vh;

    overflow: hidden;
    animation: 0.5s ease-out 0s 1 slideInFromTop;
}

.battle-banner .battle-champion {
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: center;    
}

.battle-banner {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.battle-banner .text {
    background-color: var(--bs-primary);
    width: 60px;
    height: 60px;
    border-radius: 30px;
    position: fixed;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: x-large;
    font-weight: bold;  
}

.battle-banner .name {
    font-size: x-large;
    background-color: #00000077;
    border-radius: 10px;
    padding: 5px;
    margin-top: 5px;
}

.champion-data h2, 
.battle-data h2,
.asset-data h2 {
    border-bottom: 1px solid #ffffff;
    padding-bottom: 5px;
}

.token-battles {
    display: flex;
    font-size: xx-large;
    gap: 15px;    
    justify-content: end;    
}

.token-battles .text {
    font-size: medium;
}

.token-battles > div {
    width: 90px;
}

.token-battles .badge {
    width: 100%;
}

.battle-eth .token-attrs {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    flex-wrap: wrap;
    width: 100%;
    border-radius: 5px;
}

.battle-eth .token-attrs .token-attr {
    width: 100%;
    min-width: 60px;
    background-color: var(--color-terminal-bg-primary);
    padding: 3px;
    position: relative;
    background-color: unset;
    margin-bottom: 10px;
}

.battle-eth .token-attrs .token-attr + .token-attr {
    border-top: 1px solid;
}

.battle-eth .token-attrs .token-attr .attr-title {
    width: 40%;
    padding: 5px 10px;
}

.battle-eth .token-attrs .token-attr .attr-value {
    font-weight: bold;
    width: 60%;
    position: absolute;
    left: 40%;
    top: 3px;
    padding: 5px 10px;
    text-align: right;
}  

.champion-grid-image {
    position: relative;
    height: fit-content;
}

.champion-grid {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    flex-wrap: wrap;
    border: 5px solid #fff;
    border-radius: 3px;
}

.champion-grid-mode-attack .champion-grid-cell {
    cursor: pointer;
    background-blend-mode: difference;    
    background-color: #b5b3b3;
    opacity: 0.8;
}

.champion-grid-mode-attack .champion-grid-cell:hover {
    background-color: transparent;
    opacity: 1;
}

.champion-grid-mode-attack .champion-grid-cell.attacked {
    background-color: transparent;
    background-image: url("./../css/images/battle/icon-target.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70% 70%;
    opacity: 0.9;
}

.champion-grid-cell {
    border: 1px solid #fff;
    flex: 1 0 20%;
}

.champion-grid-cell.protected {
    background-color: var(--color-bg-primary);
    background-image: url("./../css/images/battle/icon-brick-wall.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    opacity: 0.8;
}

.champion-grid .grid-buttons {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.champion-grid .grid-buttons > button {
    font-size: x-large;
}

.champion-grid-image .stats {
    position: absolute;
    top: 0px;
    right: 30px;
    color: #fff;
    font-size: xx-large;
    font-weight: bold;
    font-family: monospace;
    text-align: right;
    line-height: normal;
    border-bottom-left-radius: 20px ;
    border-bottom-right-radius: 20px ;
    background-color: #00000077;
    padding: 8px 15px;
}

.base-champion-grid {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.base-champion-grid .item {
    position: absolute;
    display: table;
    width: 20%;
    height: 20%;
    border-radius: 20px;
    background-color: #ffffffAA;
    background-color: #00000077;
    cursor: pointer;
    line-height: 20%;
    text-align: center;
    color: #ffffff55;
    font-size: xxx-large;
}

.base-champion-grid .item span {
    display: table-cell;
    vertical-align: middle;    
}

.base-champion-grid .item-1 {
    bottom: 10px;
    left: 20px;
}

.base-champion-grid .item-2 {
    bottom: 10px;
    right: 20px;
}

.base-champion-grid .item-wearable {
    top: 10px;
    left: 20px;
}

.champion-detail {
    margin-top: 10px;
    position: relative;
}

.champion-detail .nav-item {
    font-size: xx-large;
}

.battle-eth .gaugebar {
    top: 0px;
    right: 60px;
    bottom: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
    z-index: 1900;
    cursor: pointer;
}

@media screen and (max-width: 900px) {
    .champion-grid-image .stats {
        font-size: x-large;
    }
}

@media screen and (max-width: 600px) {
    .champion-list, .battle-list, .market-list {
        top: 0px;
        padding: 0px;   
    }

    .battle-eth .coming-soon-baby {
        top: 15vh;
        left: 5vw;
        width: 90vw;
    }    

    .champion-data, 
    .battle-data,
    .asset-data {
        top: 0px;
        left: 0px;
        right: 0px;
        border-radius: 0px;
        min-height: 100%;
    }    

    .token-battles {
        justify-content: center;
        font-size: x-large;
    }

    .token-battles .text {
        justify-content: center;
        font-size: small;
    }

    .champion-data h2,
    .battle-data h2,
    .asset-data h2 {
        margin-top: 10px;
        text-align: center;
    }

    .battle-banner .text {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        font-size: medium;
    }        

    .data-actions button,
    .champion-grid .grid-buttons > button {
        font-size: medium;
    }

    .champion-header {
        zoom: 0.8;
    }

    .menu-list.is-nav-open + .battle-eth-container {
        position: unset;
    }

    .battle-eth-container .title {
        font-size: x-large;
    }

    .battle-eth-container .title + div {
        animation: 0.5s ease-out 0s 1 slideInFromLeft;
    }

    .minting-screen {
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        border-radius: 0;
    }        

    .minting-screen .content {
        font-size: large;
    }        

    .champion-detail .nav-item {
        font-size: x-large;
    }

    .battle-eth .token-attrs .token-attr {
        margin-bottom: 2px;
    }    

    .battle-eth .gaugebar {
        top: 3px;
    }

    .battle-eth-container .refresh-data {
        width: 40px;
        height: 40px;
        position: fixed;
        bottom: 70px;
        right: 10px;
        z-index: 2000;
        filter: brightness(1);
    }

    .battle-eth-container .battle-eth-contents {
        margin-top: 60px;
    }    

    .market-list .market-legend {
        padding: 0px 20px;
        margin-bottom: 10px;
        font-size: medium;
    }
}
