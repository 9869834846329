@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@200&display=swap');

/* Metamask */
.button-connect {
  width: 210px;

  font-size: larger;
  font-weight: bold;
  transform: scale(0, 0);
  animation: 1s ease-out 1s 1 zoom forwards;
  margin-top: 10px;
}

#btn-download-metamask {
  width: 210px;
  height: 70px;

  font-size: larger;
  font-weight: bold;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  transform: scale(0, 0);
  animation: 1s ease-out 1s 1 zoom forwards;
}

#btn-download-metamask img {
  width: unset;
}

#btn-download-metamask div {
  text-align: center;
}

#btn-download-metamask div small {
  font-size: smaller;
}

/* Terminal */
.terminal {
  padding: 10px;
  font-family: 'IBM Plex Mono', monospace;
}

.terminal p {
  margin-bottom: 0.2rem;
}

.terminal h1.caption {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 60px;
  font-size: xx-large;
  padding-bottom: 5px;
  background-color: rgb(80, 80, 80);
  color: #fff;
  padding: 10px;
  font-weight: bold;
  animation: 1s ease-out 0s 1 slideInFromTop;
  z-index: 1000;
  cursor: pointer;
}

.terminal h1.caption .close-terminal {
  background-color: rgb(60, 60, 60);
  padding: 0px 7px;
  border-radius: 5px;
  margin-right: 10px;
  font-weight: bold;
}

.menu-list.is-nav-open + .terminal-main {
  left: 80px;
  transition: right 1s;
}

.terminal-main {
  position: absolute;
  background-color: rgb(40, 40, 40);
  position: absolute;
  top: 60px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  padding: 10px 20px;
  color: #fff;
  overflow-y: auto;
}

.terminal-main > div {
  animation: 1s ease-out 0s 1 slideInFromTop;
}

.terminal-main h1 {
  background-color: rgb(60, 60, 60);
  padding: 5px 15px;
  font-size: x-large;
  border-radius: 5px;
}

.terminal .menu-list {
  top: 60px;
}

.terminal-main .mint-machine-list .token-entry img, 
.terminal-main .mint-machine-list .token-entry img:hover {
  filter: grayscale();
}

@media screen and (max-width: 600px) {
  .terminal .menu-list {
    top: unset;
  }

  .terminal h1.caption {
    padding-top: 12px;
    font-size: small;
    height: 40px;
  }

  .terminal h1.caption .close-terminal {
    padding: 5px 7px;
    margin-right: 7px;
  }  

  .terminal h1 {
    font-size: medium;
  }

  .terminal-main {
    top: 40px;
  }

  .terminal-main * {
    font-size: small;
  }

  .menu-list.is-nav-open + .terminal-main {
    left: 0px;
    bottom: 60px;
  }

  #btn-download-metamask {
    margin: auto;
  }

  .button-connect {
    width: 150px;
    font-size: small;
  }  
}
