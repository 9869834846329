.component-factory a {
  text-decoration: none;
  color: #FFF;
  text-align: left;
}

.machine {
  max-width: 500px;
  border-radius: 15px;
  background-color: rgb(60, 60, 60);
  padding: 15px;
  border: 5px solid #555;
  font-family: monospace;
}

.machine .machine-mode, .machine .machine-status, .machine .machine-gadgets, .machine-mint-count-gadget {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.machine .machine-mode-on, .machine .machine-mode > div.machine-mode-on, .machine-mini .machine-mode-on {
  background-color: rgb(69, 103, 35);
  color:#FFF;
}

.machine .machine-mode-off {
  background-color: rgb(103, 35, 35);
  color:#FFF;
}

.machine .machine-mode > div {
  padding: 5px;
  background-color: #000;
  color: rgb(126, 126, 126);
  width: 120px;
  text-align: center;
}

.machine-gen, .machine-supply-count {
  background-color: #000;
  padding: 5px 10px;
  font-size: large;
}

.machine-mint-count-gadget {
  width: 60%;
  align-items: center;
}

.machine .machine-mint-caret {
  font-size: 60px;
  cursor: pointer;
}

.machine .machine-mint-caret.disabled {
  color: #555;
  cursor: unset;
}

.machine .machine-mint-count {
  background-color: #000;
  font-weight: bold;
  color: #FFF;
  width: 60px;
  height: 60px;
  font-size: 40px;
  text-align: center;
}

.mint-buttons {
  display: flex;
}

.machine-mint-button {
  border-radius: 5%;
  width: 100px;
  height: 60px;
  text-align: center;
  cursor: pointer;
  padding-top: 7px;
  font-size: 15px;
  font-weight: bold;
  background-color: #dc3545;
  border: 3px solid;
  border-color: #e78888;
  border-right-color: #a72a2a;
  border-bottom-color: #a72a2a;  
  color: #fff;
}

.machine .machine-display {
  border-radius: 5px;  
  border-bottom: 1px solid #aaa;
  border-right: 1px solid #aaa;
  border-left: 1px solid #444;
  border-top: 1px solid #444;  
}

.machine .machine-monitor {
  background-color: #000;
  height: 130px;
  margin-top: 10px;
  padding: 3px 10px;
  text-align: center;
  word-break: break-word;
}

.machine .machine-display-error {
  background-color: rgb(111, 41, 41);
}

.mint-machine-list {
  padding: 0px 10px;
  gap: 10px;
}

.mint-machine-list .token-entry {
  width: 250px;
  height: 400px;
  background-color: #fff;
  padding: 0px;
  border: 0;
  margin: 0;
}

.mint-machine-list .token-entry .title {
  font-size: medium;
  font-weight: bold;
}

.mint-machine-list .token-entry img {
  width: 100%;
  height: unset;
  padding: 10px;
  padding-bottom: 20px;
  filter: none;
  border: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.machine-mini {
  color: #000;
  padding: 10px;
}

.machine-mini .machine-mint-count-gadget {
  width: 100%;
  font-size: x-large;
  margin-top: 20px;  
}

.machine-mini .machine-mint-button {
  border: 0;
  border-radius: 10px;
  margin: auto;
  margin-top: 10px;
  width: 45%;
  height: 60px;
  background-color: rgb(148 103 249);
}

.machine-mini .mint-type {
  position: absolute;
  top: 5px;
  right: 10px;

  padding: 3px 5px;
  border-radius: 3px;
  background-color: rgb(148 103 249);
  color:#FFF;
}

.mint-machine-list .token-entry.collection-gen-2 img {
  background-color: #f3dd4f;
}

.mint-machine-list .token-entry.collection-pixel-rebel img {
  background-color: #ff6dc9;
}

.mint-machine-list .token-entry .minting-screen {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding: 10px 20px;
  border-radius: 15px;
  background-color: #000000AA;
  z-index: 2002;
  overflow: hidden;
  word-break: break-all;
  font-size: medium;
}

.mint-machine-list .token-entry .minting-screen .close-btn {
  position: absolute;
  cursor: pointer;
  bottom: 10px;
  padding: 3px 10px;
  border-radius: 5px;
  background-color: #fff;
  color: #000;
  right: 10px;
}

.mint-price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 600px) {
  .component-factory {
    font-size: small;
  }

  .machine .machine-mint-button > * {
    font-size: 28px;
  }  

  .machine .machine-mode > div {
    width: 100px;
  }  

  .mint-machine-list {
    padding: 0;
    gap: 15px;
  }

  .mint-machine-list .token-entry {
    width: 95%;
    height: auto;
  }

  .mint-machine-list .token-entry img {
    width: unset;
    height: 100px;
    float: left;
    margin-right: 10px;
    border-radius: 0px;
    border-top-left-radius: 15px;
    --border-bottom-left-radius: 15px;
  }

  .machine-mini .machine-mint-button {
    margin-bottom: 10px;
  }

  .machine-mini {
    padding: 5px;
    font-size: medium;
  }
}