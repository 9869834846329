.voucher-modal {
  width: 70vw;
  max-width: 70vw;
}

.voucher-modal .modal-content {
  border-radius: 20px;
  opacity: 0.9;
}

.voucher-modal .token-list {
  justify-content: center;
}

.voucher-modal .token-list .token-entry {
  border: 0;
}

@media screen and (max-width: 600px) {
  .voucher-modal {
    width: unset;
    max-width: unset;
  }

  .voucher-modal .token-list .token-entry, .voucher-modal .token-list .token-entry img {
    width: 80px;
    height: 80px;
  }
}